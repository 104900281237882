export const votes = [
    {"id": 14, "votes": [8, 72]},
    {"id": 13, "votes": [13, 26, 65]},
    {"id": 12, "votes": [45, 6, 13]}, 
    {"id": 11, "votes": [14, 3]}, 
    {"id": 10, "votes": [1, 25]}, 
    {"id": 9, "votes": [8, 3]}, 
    {"id": 8, "votes": [12, 1]}, 
    {"id": 7, "votes": [14, 4]}, 
    {"id": 6, "votes": [20, 4, 15]}, 
    {"id": 5, "votes": [8, 10]}, 
    {"id": 4, "votes": []}, 
    {"id": 3, "votes": []}, 
    {"id": 2, "votes": []}, 
    {"id": 1, "votes": []}, 
    {"id": 0, "votes": []}
]