/** @jsxImportSource @emotion/react */
import tw from 'twin.macro'
import GoogleMapReact from 'google-map-react';
import {mapStyle} from "./map-style";
import {useRef, useState} from "react";
import useSupercluster from "use-supercluster";
import MessagePopUp from "../postPopUp";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";

const Container = tw.div`fixed inset-0 top-16 flex justify-center`;

// Marqueurs
const MarkerContainer = tw.div`hover:cursor-pointer flex flex-col justify-center items-center`;
const MarkerPicture = tw.div`border-solid border-black border-2`;


//Clusters
const ClusterContainer = tw.div`hover:cursor-pointer bg-primaryOrange border-solid border-black border-2 text-xl text-white font-bold flex justify-center items-center p-4`;


function Marker(props) {
    return(
        <MarkerContainer
            style={{transform:"translate(-50%,-50%)"}}
            onClick={props.handleClick}
        >
            <MarkerPicture
                style={{
                    width: props.zoom * 10,
                    height: props.zoom * 10,
                    borderRadius:"50%",
                    backgroundImage: `url(${props.post.cover})`,
                    backgroundSize: "cover",
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}
            >
            </MarkerPicture>
        </MarkerContainer>
    )
}

function formatDataforCluster(data){
    return(
        data.map((post)=>{
            return(
                {
                    type: 'Feature',
                    properties: {
                        itemId: post.id,
                        cluster: false
                    },
                    geometry: {
                        type: 'Point',
                        coordinates: [post.longitude, post.latitude],
                    }
                }
            )
        })
    )
}


export default function MapMap({posts, likes}) {

    const navigate = useNavigate();
    const location = useLocation();

    const [isOpen, setIsOpen] = useState(false)
    const [popUpId, setPopUpId] = useState(null)

    // Setup MAP
    const mapRef = useRef();

    // Format data for clusters
    const points = formatDataforCluster(posts);

    // get map bounds
    const [bounds, setBounds] = useState(null);
    const [zoom, setZoom] = useState(4);

    // get clusters
    const { clusters, supercluster } = useSupercluster({
        points,
        bounds,
        zoom,
        options: { radius: 150, maxZoom: 20 }
    });

    const defaultProps = {
        center: {
            lat: -24.628512832324486,
            lng: 134.52369911474156
        },
        zoom: window.innerWidth < 500 ? 3 : 4
    };

    const handleClick = (id) => {
        setPopUpId(id);
        setIsOpen(true);
        navigate('/map/' + id.toString());
    }

    const togglePopUp = () => {
        setIsOpen(false)
        navigate('/map');
    }

    useEffect(
        ()=>{
            if (location.pathname === "/map") {
                setIsOpen(false);
            }
            if (location.pathname !== "/map" && isOpen === false){
                navigate('/map')
            }
        },
        [location]
    )

    return (
        <Container>
            <div style={{ height: "100%", width: "100%" }}>
                <GoogleMapReact
                    options={{
                        draggableCursor:"default",
                        styles:mapStyle,
                        minZoom:window.innerWidth < 500 ? 3 : 4,
                        mapTypeControl:false,
                        streetViewControl:false,
                        zoomControl:false,
                        fullscreenControl: false
                    }}
                    bootstrapURLKeys={{ key: "AIzaSyDn2coJZCiAz2E1E3g7DIHHk-kFVME-GUY" }}
                    yesIWantToUseGoogleMapApiInternals
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}

                    onGoogleApiLoaded={({ map }) => {
                        mapRef.current = map;
                    }}

                    onChange={({ zoom, bounds }) => {
                        setZoom(zoom);
                        setBounds([
                            bounds.nw.lng,
                            bounds.se.lat,
                            bounds.se.lng,
                            bounds.nw.lat
                        ]);
                    }}


                >

                    {clusters.map(cluster => {
                        const post = posts.filter(post=> post.id === cluster.properties.itemId)[0];

                        const [longitude, latitude] = cluster.geometry.coordinates;
                        const {
                            cluster: isCluster,
                            point_count: pointCount
                        } = cluster.properties;

                        if (isCluster) {
                            return (
                                <ClusterContainer
                                    key={cluster.id}
                                    lat={latitude}
                                    lng={longitude}
                                    onClick={
                                        () => {
                                            const expansionZoom = Math.min(
                                                supercluster.getClusterExpansionZoom(cluster.id),
                                                20
                                            );
                                            mapRef.current.setZoom(expansionZoom);
                                            mapRef.current.panTo({ lat: latitude, lng: longitude });
                                        }
                                    }
                                    style={{
                                        transform:"translate(-50%,-50%)",
                                        borderRadius:"50%",
                                        width:` ${30 + (pointCount / points.length) * 20}px`,
                                        height: `${30 + (pointCount / points.length) * 20}px`,
                                    }}
                                >
                                    {pointCount}
                                </ClusterContainer>
                            );
                        }

                        return (
                            <Marker
                                key={post.id}
                                lat={latitude}
                                lng={longitude}
                                post={post}
                                zoom={zoom}
                                handleClick={()=>{handleClick(post.id)}}
                            />
                        );
                    })}


                </GoogleMapReact>

                {isOpen &&
                    <MessagePopUp isOpened={isOpen} post={posts.filter((post)=> post.id === popUpId)[0]} likeObj={likes.filter((e)=> e.id === popUpId)[0]} handleClose={togglePopUp}/>
                }

            </div>
        </Container>
    );
}