/** @jsxImportSource @emotion/react */
import Main from "./pages/main-page/main-main";
import { visitors } from "./data/visitors";

function App() {
  return (
      <Main visitors={visitors.visitors}/>
  );
}

export default App;
