/** @jsxImportSource @emotion/react */
import tw from 'twin.macro'
import { Parallax, ParallaxLayer } from '@react-spring/parallax'
import { Wrapper as PageWrapper } from "../../layouts/sections";
import BlogHeader from "../header";
import { useRef } from "react";
import background2 from "../../ParallaxObjects/background2.svg"
import background from "../../ParallaxObjects/background.svg"
import mountain2 from "../../ParallaxObjects/mountain2.svg"
import mountain from "../../ParallaxObjects/mountain.svg"
import trees4 from "../../ParallaxObjects/trees4.svg"
import trees3 from "../../ParallaxObjects/trees3.svg"
import trees2 from "../../ParallaxObjects/trees2.svg"
import trees from "../../ParallaxObjects/trees.svg"
import plane from "../../ParallaxObjects/plane.svg"
import van from "../../ParallaxObjects/van.svg"
import traveller from "../../ParallaxObjects/traveller.svg"

import scene from "../../ParallaxObjects/completeScene.svg"

const TopGradient = tw.div`h-16 bg-gradient-to-t from-paleBlue to-transparent`;
const TitleContainer = tw.div`font-display text-7xl text-black text-center font-black px-3 mt-32 mb-10 select-none`
const TitleDescription = tw.div`font-display text-3xl text-black text-center font-bold px-4 select-none`
const TitleBisContainer = tw.div`font-display text-5xl text-black text-center font-black mt-32 mb-8 select-none`
const TransitionGradient = tw.div`h-64 bg-darkViolet`

const Paragraph = tw.div``;
const ParagraphTitleContainer = tw.div`text-primaryOrange text-4xl font-black mb-4`;
const TextContainer = tw.div`text-white text-xl font-bold text-justify`

const TextLeft = tw.div`ml-8 w-7/12 lg:w-5/12 text-left`
const TextRight = tw.div`mr-8 w-7/12 lg:w-5/12 text-right`
const TextCenter = tw.div`w-5/12 text-center`

const VisitorCounter = tw.div`text-5xl text-white text-center font-black mb-4`
const VisitorWorry = tw.div`text-xl text-white text-center font-bold`

const ConclusionContainer = tw.div`text-5xl text-white text-center font-bold mb-4`


const MobileTop = tw.div`bg-paleBlue`
const Title = tw.div`font-display text-4xl text-black text-center font-black pt-12 mb-4 mx-3 select-none`
const Description = tw.div`font-display text-black text-center font-bold mb-4 mx-4 select-none`

const MobileVisitorsCounter = tw.div`text-center text-white text-4xl font-black mb-4`
const MobileVisitorWorry = tw.div`text-white text-center font-bold mb-10 mx-2`

const MobilePar = tw.div`flex flex-col sm:w-8/12 mx-3 `
const MobileParTitle = tw.div`text-primaryOrange text-3xl font-black mt-6 mb-2`
const MobileParText = tw.div`text-white text-base font-medium`

const MobileConclusion = tw.div`text-center font-bold text-white text-3xl my-12`




const firstParTitle = "Le grand départ"
const firstParContent = "Première question, quand ? Je pars le 7 février 2023 et rentre en France environ 6 mois plus tard, à une date encore indéterminée pour l'instant. C'est parti pour un peu plus de 30h de voyage dont 20h en vol. Je fais une rapide escale (10h ouch...) à Dubaï avant d'arriver à BRISBANE qui est ma destination finale. J'écris cela quelques heures avant le départ : le stress pré-voyage commence à me titiller (ehh oui l'Australie ça fait loin et 6 mois c'est quand même un peu long). J'ai quand même sacrément hâte d'y être :)"
const secondParTitle = "Et une fois là-bas, il se passe quoi ?"
const secondParContent = "Grande question... la base de tout c'est que je vais passer un semestre à UQ, la fac de BRISBANE. Cela étant dit l'objectif est de découvrir un maximum de choses durant la période des cours, que ce soit tester des sports qui sonnent très Australiens comme le cricket et le surf, ou partir visiter le pays durant les week-end. Les cours se terminant courant Juin, il me restera entre 1 à 2 mois pour partir à l'aventure. J'espère pouvoir louer un van et rouler à travers l'Australie en mode 'baroudeur', pour ensuite m'envoler visiter un des pays voisin, que ce soit la Nouvelle-Zélande, la Papouasie ou même Bali!"
const thirdParTitle = "Un Contenu de K-lité"
const thirdParContent = "Bon... aussi bien développé qu'il puisse l'être (c'est un bô site hein), un blog reste complètement nul si son contenu est nul. Fact. Du coup je vais m'efforcer de poster des étapes intéressantes et amusantes de mon voyage, tout en les accompagnant de photos bien instagrammables (j'ai foi en moi). Si vous avez de la chance, j'achète un drone et je commence à monter quelques vidéos bien sympatoches (RIP mon ordi si c'est le cas)..."
const goodbyeMessage = "Je vous dis donc à très bientôt ;)"

export default function Main({ visitors }) {

    const parallax = useRef()

    return (
        <PageWrapper
            css={tw`relative`}
        >
            <BlogHeader css={tw`fixed top-0 left-0 right-0 z-20`} />
            <div
                css={tw`hidden md:block`}
                style={{ width: '100%', height: '100%' }}
            >
                <Parallax ref={parallax} pages={7}>

                    <ParallaxLayer
                        offset={0}
                        speed={0}
                        factor={7}
                        style={{ backgroundColor: "#441944" }}
                    //sticky={{ start: 0, end: 1 }}
                    />

                    <ParallaxLayer
                        offset={0}
                        speed={1}
                    //sticky={{ start: 0, end: 1 }}
                    >
                        <div style={{ height: '50vh', backgroundColor: "#CBF3F0" }} />
                    </ParallaxLayer>

                    <ParallaxLayer
                        offset={0.4}
                        speed={1}
                    //sticky={{ start: 0.5, end: 1.5 }}
                    >
                        <img src={background2} alt={''} style={{ width: '100%' }} />
                    </ParallaxLayer>

                    <ParallaxLayer
                        offset={0.4}
                        speed={1.1}
                    //sticky={{ start: 0.5, end: 1.5 }}
                    >

                        <img src={background} alt={''} style={{ width: '100%' }} />
                    </ParallaxLayer>

                    <ParallaxLayer
                        offset={0}
                        speed={2}
                        //sticky={{ start: 0.5, end: 1.5 }}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: 'center',
                            justifyContent: 'start',
                        }}
                    >

                        <TitleContainer>
                            Bienvenue chez Ben en Australie !
                        </TitleContainer>
                        <TitleDescription>
                            Sur ce site tu pourras suivre mon voyage...
                        </TitleDescription>
                    </ParallaxLayer>

                    <ParallaxLayer
                        offset={0.8}
                        speed={2.6}
                    //sticky={{ start: 0.5, end: 1.5 }}
                    >

                        <TitleBisContainer>
                            ...au pays des Kangourous!
                        </TitleBisContainer>
                    </ParallaxLayer>

                    <ParallaxLayer
                        offset={0.6}
                        speed={1.2}
                    //sticky={{ start: 0.7, end: 1.7 }}
                    >

                        <img src={mountain2} alt={''} style={{ width: '100%' }} />
                    </ParallaxLayer>

                    <ParallaxLayer
                        offset={0.6}
                        speed={1.3}
                    //sticky={{ start: 0.7, end: 1.7 }}
                    >

                        <img src={mountain} alt={''} style={{ width: '100%' }} />
                    </ParallaxLayer>

                    <ParallaxLayer
                        offset={0.55}
                        speed={1.4}
                    //sticky={{ start: 0.65, end: 1.65 }}
                    >

                        <img src={trees4} alt={''} style={{ width: '100%' }} />
                    </ParallaxLayer>

                    <ParallaxLayer
                        offset={0.6}
                        speed={1.5}
                    //sticky={{ start: 0.7, end: 1.7 }}
                    >

                        <img src={trees3} alt={''} style={{ width: '100%' }} />
                    </ParallaxLayer>

                    <ParallaxLayer
                        offset={0.8}
                        speed={1.8}
                    //sticky={{ start: 0.9, end: 1.9 }}
                    >

                        <img src={trees2} alt={''} style={{ width: '100%' }} />
                    </ParallaxLayer>

                    <ParallaxLayer
                        offset={0.9}
                        speed={2.2}
                        //sticky={{ start: 1.2, end: 2.2 }}
                        style={{
                            margin: 0,
                        }}
                    >
                        <img src={trees} alt={''} style={{ width: '100%' }} />
                    </ParallaxLayer>

                    <ParallaxLayer
                        offset={0.99}
                        speed={1.6}
                        style={{
                            height: "100vh",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: 'end',
                            alignItems: "center",
                        }}
                    >
                        <VisitorCounter>
                            J'ai compté jusqu'à la {visitors}ème visite du site!
                        </VisitorCounter>
                        <VisitorWorry>
                            Depuis le compte s'est arrêté mais dans l'idée multipliez encore par 2 ou 3...
                        </VisitorWorry>
                    </ParallaxLayer>


                    <ParallaxLayer
                        offset={1.2}
                        speed={2}
                        sticky={{ start: 1.2, end: 2 }}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: 'start',
                            justifyContent: 'center',
                        }}
                    >
                        <TextLeft>
                            <ParagraphTitleContainer>
                                {firstParTitle}
                            </ParagraphTitleContainer>
                            <TextContainer>
                                {firstParContent}
                            </TextContainer>
                        </TextLeft>
                    </ParallaxLayer>

                    <ParallaxLayer
                        offset={1.8}
                        speed={1.5}
                        //sticky={{ start: 1.2, end: 2.2 }}
                        style={{
                            display: "flex",
                            justifyContent: "end",
                        }}
                    >
                        <img src={plane} alt={"Plane"} style={window.innerWidth > 1024 ? { width: '50%', flex: "grow" } : { width: '35%', flex: "grow" }} />
                    </ParallaxLayer>


                    <ParallaxLayer
                        offset={1.9}
                        speed={2}
                        sticky={{ start: 2.8, end: 3.6 }}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: 'end',
                            justifyContent: 'center',
                        }}
                    >
                        <TextRight>
                            <ParagraphTitleContainer>
                                {secondParTitle}
                            </ParagraphTitleContainer>
                            <TextContainer>
                                {secondParContent}
                            </TextContainer>
                        </TextRight>
                    </ParallaxLayer>

                    <ParallaxLayer
                        offset={3.1}
                        speed={1.5}
                        //sticky={{ start: 1.2, end: 2.2 }}
                        style={{
                            display: "flex",
                            justifyContent: "start",
                        }}
                    >
                        <img src={van} alt={"Van"} style={window.innerWidth > 1024 ? { width: '50%', flex: "grow" } : { width: '35%', flex: "grow" }} />
                    </ParallaxLayer>



                    <ParallaxLayer
                        offset={3.4}
                        speed={2}
                        sticky={{ start: 4.4, end: 4.8 }}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <TextCenter>
                            <ParagraphTitleContainer>
                                {thirdParTitle}
                            </ParagraphTitleContainer>
                            <TextContainer>
                                {thirdParContent}
                            </TextContainer>
                        </TextCenter>
                    </ParallaxLayer>

                    <ParallaxLayer
                        offset={5.8}
                        speed={1}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <TextCenter css={tw`w-full`}>
                            <ConclusionContainer>
                                {goodbyeMessage}
                            </ConclusionContainer>
                        </TextCenter>
                    </ParallaxLayer>


                </Parallax>
            </div>




            <div
                css={tw`md:hidden flex flex-col bg-darkViolet`}
            >
                <MobileTop>
                    <Title>
                    Bienvenue chez Ben en Australie !
                    </Title>
                    <Description>
                        Sur ce site tu pourras suivre mon voyage au pays des Kangourous !
                    </Description>
                </MobileTop>
                <img src={scene} alt="Kangourou scene" style={{ width: '100%' }} />
                <div
                    css={tw`flex flex-col p-3`}
                >
                    <MobileVisitorsCounter>
                        J'ai compté jusqu'à la {visitors}ème visite du site!
                    </MobileVisitorsCounter>
                    <MobileVisitorWorry>
                        Depuis le compte s'est arrêté mais dans l'idée multipliez encore par 2 ou 3...
                    </MobileVisitorWorry>

                    <MobilePar css={tw`text-left`}>
                        <MobileParTitle>
                            {firstParTitle}
                        </MobileParTitle>
                        <MobileParText>
                            {firstParContent}
                        </MobileParText>
                    </MobilePar>

                    <div
                        css={tw`flex`}
                    >
                        <img src={van} alt={"Van"} style={{ width: '50%', flex: "grow" }} />
                        <img src={plane} alt={"Plane"} style={{ width: '50%', flex: "grow" }} />
                    </div>
                    <MobilePar css={tw`text-right self-end`}>
                        <MobileParTitle>
                            {secondParTitle}
                        </MobileParTitle>
                        <MobileParText>
                            {secondParContent}
                        </MobileParText>
                    </MobilePar>

                    <div
                        css={tw`flex mt-4 mb-0`}
                    >
                        <img src={traveller} alt={"Surf Board"} style={{ width: '100%', flex: "grow" }} />
                    </div>

                    <MobilePar css={tw`text-center self-center`}>
                        <MobileParTitle>
                            {thirdParTitle}
                        </MobileParTitle>
                        <MobileParText>
                            {thirdParContent}
                        </MobileParText>
                    </MobilePar>

                    <MobileConclusion>
                        {goodbyeMessage}
                    </MobileConclusion>



                </div>

            </div>
        </PageWrapper>
    );
}