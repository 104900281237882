/** @jsxImportSource @emotion/react */
import tw from 'twin.macro'
import {useState} from "react";
import {Title, TitleContainer, TitleLine} from "../layouts/headings";

const Container = tw.div`flex flex-col flex-nowrap mb-4`;

const VideoContainer = tw.div`
px-4 md:px-24
`;

export default function YTLinks(props) {

    return (
        <Container>
            <TitleContainer>
                <TitleLine/>
                <Title>
                    Vidéos
                </Title>
                <TitleLine/>
            </TitleContainer>

            <VideoContainer>
                {props.links.map((link,index)=>{
                    return(
                        <iframe
                            key={index}
                            style={{
                                aspectRatio:"16/9",
                                width:"100%"
                            }}
                            src={link}
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; picture-in-picture"
                            allowFullScreen
                        >

                        </iframe>
                    )
                })}
            </VideoContainer>
        </Container>

    );
}