/** @jsxImportSource @emotion/react */
import tw from 'twin.macro'
import { useEffect, useRef, useState } from "react";
import { XMarkIcon, HeartIcon, HandThumbDownIcon, HandThumbUpIcon } from "@heroicons/react/24/outline";
import { PlayCircleIcon, PauseCircleIcon } from "@heroicons/react/24/solid";
import { motion } from "framer-motion"
import Gallery from "./postPictureGallery";
import { Title, TitleContainer, TitleLine } from "../layouts/headings";
import YTLinks from "./postYTsection";
import { Oval } from 'react-loader-spinner'
import PopSurvey from "./popSurvey";
import PopAnecdote from "./popAnecdote";
import { votes as allVotes } from "../data/votes"
import { comments as allComments } from "../data/comments"


const Wrapper = tw.div`fixed cursor-pointer inset-0 outline-none`;
const LargeXCrossContainer = tw.div`sm:cursor-pointer hidden md:block fixed text-white w-6 h-6 top-1/40 right-1/40 flex justify-center items-center`;
const Container = tw.div`
cursor-auto
flex fixed py-0 bg-white
inset-0 rounded-none
md:top-1/20 md:rounded-xl
`;

const ScrollableContainer = tw.div`flex-col w-full overflow-y-scroll p-3`;

const Header = tw.div` flex justify-end pb-3 h-6`;
const HeaderClose = tw.div`h-6 w-6 flex text-black justify-end items-start rounded-full cursor-pointer md:hidden`;

const BodyContent = tw.div`
flex flex-col
sm:px-4 md:px-12 lg:px-24 xl:px-40
`

const PostHeader = tw.div`
flex mb-4
sm:px-4 md:px-8 lg:px-24 xl:px-28
`;
const TitleBlock = tw.div`flex-1 flex flex-col justify-center`
const PostTitle = tw.div`text-black font-bold text-xl xl:text-2xl pr-4`
const ChipsContainer = tw.div`flex flex-wrap`
const Chip = tw.div`text-sm text-primaryBlue rounded-full border-solid border-primaryBlue border-2 px-2 mr-2 my-1`;
const RightContainer = tw.div`w-4/12 sm:w-4/12 flex flex-col sm:flex-row justify-center md:justify-between items-center`
const ButtonsContainer = tw.div`w-full flex justify-center sm:justify-start items-center`
const LikeButton = tw.div`sm:cursor-pointer flex items-center border-solid border-black border-2 py-1 h-5 sm:h-7 px-2 rounded-xl mx-1 sm:mr-4 sm:ml-0 lg:mr-8`
const LikeLogoContainer = tw.div`flex flex-1 justify-center items-center w-5 h-5 sm:w-7 sm:h-7`
const LikeText = tw.div`text-black text-base sm:text-lg font-normal text-center flex-1 self-center`

const DislikeButton = tw(LikeButton)`items-center bg-blue-500 text-white font-normal sm:mr-4 sm:ml-0 lg:mr-8`

const PlayMusicIconContainer = tw.div`flex items-center justify-center mt-1 sm:mt-0 text-center`

const BannerContainer = tw.div`
flex justify-center items-center
mb-4
`;

const MainText = tw.div`whitespace-pre-wrap text-left text-black`


const NewComContainer = tw.div`flex flex-col md:flex-row`
const NewComment = tw.input`
flex-1
font-display
outline-none
rounded-md
p-4 text-black font-semibold text-base
border-solid border-2 border-gray-300
hocus:shadow-md hocus:border-primaryBlue
transition duration-300
md:mr-8
`
const ButtonContainer = tw.div`flex justify-center items-center mt-4 md:mt-0`
const SubmitButton = tw.div`sm:cursor-pointer rounded-md p-3 bg-primaryOrange text-white font-semibold hover:bg-primaryBlue transition duration-300`
const CommentSection = tw.div`self-center w-full md:w-8/12 mt-6 flex flex-col mb-24`
const Comment = tw.div`
my-3 first:mt-0 last:mb-0 
p-4
rounded-md shadow-md 
text-black font-semibold text-center
border-solid border-2 border-primaryOrange
`

const ErrorLabel = tw.div`mt-4 text-red-500 font-semibold text-center`

const NoComment = tw.div`rounded-md p-2 border-solid border-2 border-primaryBlue text-primaryBlue font-semibold text-center`

//Close the component if clicked outside
export function useOutsideClose(ref, handleClose) {

    useEffect(() => {
        /**
         * Close if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                handleClose()
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}


const popUpVariants = {
    closed: {
        translateY: "10%",
        opacity: "0%",
        //scale:0.95
    },
    opened: {
        translateY: "0%",
        opacity: "100%",
    }
}

const dislikeMessageVariants = {
    rest: {
        height: 0,
        opacity: 0,
    },
    disliked: {
        height: 'auto',
        opacity: 1,
    },
}

const dislikeMessages = [
    "Je regrette mais tu n'es pas habilité à ne pas aimer ce post... 😼",
    "Eh bah bravo... j'espère que tu avais une bonne excuse pour autant t'acharner sur ce bouton dislike! 😈",
    "C'était beaucoup d'effort pour finalement pas grand chose... 🤭",
    "Tu peux essayer de dislike autant que tu veux c'est moi le boss ici eheh 🤡",
    "Si tu veux dislike c'est sur le coeur qu'il faut cliquer! 🙄",
    "Naughty naughty... 😏",
]


export default function MessagePopUp({ post, likeObj, handleClose, isOpened }) {

    const comments = allComments.find(obj => obj.id === post.id).comments
    const votes = allVotes.find(obj => obj.id === post.id).votes

    const [isLiked, setIsLiked] = useState(false);

    const wrapperRef = useRef(null);
    useOutsideClose(wrapperRef, handleClose);

    //const [song,setSong] = useState(new Audio(post.song))
    const [loaded, setLoaded] = useState(false)
    const [isPlaying, setIsPlaying] = useState(false)


    const audio = useRef();

    function handleLoaded() {
        setLoaded(true)
    }

    function toggleAudio() {
        if (isPlaying === true) {
            audio.current.pause()
            setIsPlaying(false)
        }
        else {
            audio.current.play().then(() => {
                setIsPlaying(true)
            })
        }
    }

    function handleLike(){
        setIsLiked(true)
    }

    useEffect(() => {
        audio.current.load()
    }, [audio])


    const dataGallery = post.pictures.map((pic, index) => {
        return (
            {
                id: index,
                src: pic.src,
                comment: pic.comment
            }
        )
    })

    const [error, setError] = useState(false)

    const [counter, setCounter] = useState(0)
    const maxCounter = Math.floor(1 + Math.random() * 4)
    const [inverted, setInverted] = useState(false)
    const [disliked, setDisliked] = useState(false)
    const [dislikeDisplay, setDislikeDisplay] = useState(dislikeMessages[Math.floor(Math.random() * dislikeMessages.length)])

    const handleDislike = () => {
        if (counter < maxCounter) {
            setCounter(prevState => prevState + 1)
            setInverted(prevState => !prevState)
        }
        else {
            if (disliked === false) {
                setDisliked(true)
            }
        }
    }

    return (
        <Wrapper style={{ backgroundColor: "rgba(0,0,0,0.8)" }}>
            <LargeXCrossContainer style={{ transform: "translate(12px,-12px)" }}>
                <XMarkIcon css={tw`w-6 h-6`} />
            </LargeXCrossContainer>
            <motion.div
                style={{ position: "fixed", height: "100%", width: "100%", cursor: "default" }}
                variants={popUpVariants}
                initial="closed"
                animate={isOpened ? "opened" : "closed"}
                transition={{
                    type: "easeIn",
                    duration: 0.5
                }}
            >
                <Container ref={wrapperRef}>
                    <ScrollableContainer>
                        <Header>
                            <HeaderClose onClick={handleClose}>
                                <XMarkIcon css={tw`w-6 h-6`} />
                            </HeaderClose>
                        </Header>
                        <BodyContent>
                            <motion.div
                                css={
                                    disliked &&
                                    tw`flex sm:mx-2 md:mx-4 lg:mx-12 xl:mx-16 text-center p-2 mb-4 items-center justify-center rounded-md bg-blue-500 text-white text-sm sm:text-base md:text-lg font-semibold`
                                }
                                variants={dislikeMessageVariants}
                                initial="rest"
                                animate={!disliked ? "rest" : "disliked"}
                                transition={{
                                    type: "easeIn",
                                    duration: 0.5
                                }}
                                style={{ userSelect: 'none', msUserSelect: 'none' }}
                            >
                                {dislikeDisplay}
                            </motion.div>
                            <PostHeader>
                                <TitleBlock>
                                    <PostTitle>
                                        {post.title}
                                    </PostTitle>
                                    <ChipsContainer>
                                        {post.hTags.map((tag, index) => {
                                            return (
                                                <Chip key={index}>
                                                    {tag}
                                                </Chip>
                                            )
                                        })}
                                    </ChipsContainer>
                                </TitleBlock>
                                <RightContainer>
                                    <div
                                        css={tw`w-full flex justify-center sm:justify-start items-center`}
                                    >
                                        {inverted && (
                                            <motion.div
                                                layoutId="dislike"
                                            //transition={{ duration: 0.5 }}
                                            >
                                                <DislikeButton
                                                    onClick={handleDislike}
                                                    css={disliked ? tw`bg-white` : tw``}
                                                >
                                                    <LikeLogoContainer css={tw`mr-0`}>
                                                        {disliked ?
                                                            <HandThumbUpIcon css={tw`w-5 h-5 sm:w-7 sm:h-7 text-black fill-blue-500`} />
                                                            :
                                                            <HandThumbDownIcon css={tw`w-5 h-5 sm:w-7 sm:h-7 text-black fill-white`} />
                                                        }
                                                    </LikeLogoContainer>
                                                </DislikeButton>
                                            </motion.div>
                                        )}

                                        <motion.div
                                            layoutId="like"
                                        //transition={{ duration: 0.5 }}
                                        >
                                            <LikeButton
                                                onClick={handleLike}
                                                css={isLiked ? tw`bg-white transition duration-100` : tw`bg-red-500 transition duration-100`}
                                            >
                                                <LikeLogoContainer>
                                                    <HeartIcon
                                                        css={isLiked ? tw`w-5 h-5 sm:w-7 sm:h-7 fill-red-500 transition duration-100` : tw`w-5 h-5 sm:w-7 sm:h-7 fill-white transition duration-100`} />
                                                </LikeLogoContainer>
                                                <LikeText css={isLiked ? tw`ml-2` : tw`ml-0`}>
                                                    {isLiked ?
                                                        likeObj.likes + 1
                                                        :
                                                        ""
                                                    }
                                                </LikeText>
                                            </LikeButton>
                                        </motion.div>


                                        {!inverted && (
                                            <motion.div
                                                layoutId="dislike"
                                            //transition={{ duration: 0.5 }}
                                            >
                                                <DislikeButton
                                                    onClick={handleDislike}
                                                    css={disliked ? tw`bg-white` : tw``}
                                                >
                                                    <LikeLogoContainer css={tw`mr-0`}>
                                                        {disliked ?
                                                            <HandThumbUpIcon css={tw`w-5 h-5 sm:w-7 sm:h-7 text-black fill-blue-500`} />
                                                            :
                                                            <HandThumbDownIcon css={tw`w-5 h-5 sm:w-7 sm:h-7 text-black fill-white`} />
                                                        }
                                                    </LikeLogoContainer>
                                                </DislikeButton>
                                            </motion.div>
                                        )}
                                    </div>


                                    <PlayMusicIconContainer
                                        onClick={() => {
                                            if (loaded === true) {
                                                toggleAudio()
                                            }
                                        }}
                                    >

                                        <audio
                                            src={post.song}
                                            preload={'auto'}
                                            ref={audio}
                                            onCanPlay={handleLoaded}
                                        />
                                        {loaded === false &&
                                            <Oval
                                                width={36}
                                                height={36}
                                                color="#FF9F1C"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                                //visible={!loaded}
                                                ariaLabel='oval-loading'
                                                secondaryColor="#FFBF69"
                                                strokeWidth={4}
                                                strokeWidthSecondary={4}
                                            />
                                        }

                                        {loaded === true ?
                                            (isPlaying === true ?
                                                <PauseCircleIcon css={tw`w-11 h-11 sm:w-12 sm:h-12 sm:cursor-pointer text-primaryOrange hover:text-primaryBlue transition duration-300`} />
                                                :
                                                <PlayCircleIcon
                                                    css={tw`w-11 h-11 sm:w-12 sm:h-12 sm:cursor-pointer text-primaryOrange hover:text-primaryBlue transition duration-300`}
                                                />
                                            )
                                            :
                                            <></>
                                        }
                                    </PlayMusicIconContainer>
                                </RightContainer>
                            </PostHeader>

                            <BannerContainer>
                                <img style={{ width: "100%", borderRadius: "0.75rem" }} src={post.banner} alt={"Post banner"} />
                            </BannerContainer>

                            {Object.keys(post.survey).length !== 0 && votes !== null &&
                                <>
                                    <TitleContainer>
                                        <TitleLine />
                                        <Title>
                                            Sondage du jour
                                        </Title>
                                        <TitleLine />
                                    </TitleContainer>
                                    <PopSurvey
                                        postId={post.id}
                                        survey={post.survey}
                                        votes={votes}
                                    />
                                </>
                            }

                            <TitleContainer>
                                <TitleLine />
                                <Title>
                                    Description
                                </Title>
                                <TitleLine />
                            </TitleContainer>
                            <MainText
                                dangerouslySetInnerHTML={{
                                    __html: post.content
                                }}
                            />
                            {post.youtubeLinks.length > 0 &&
                                <YTLinks links={post.youtubeLinks} />
                            }
                            {post.anecdote !== "" &&
                                <>
                                    <TitleContainer>
                                        <TitleLine />
                                        <Title>
                                            Anecdote du jour
                                        </Title>
                                        <TitleLine />
                                    </TitleContainer>
                                    <PopAnecdote
                                        audioSrc={post.anecdote}
                                        musicPlaying={isPlaying}
                                        toggleMusic={toggleAudio}
                                    />
                                </>
                            }

                            <Gallery postId={post.id} images={dataGallery} />

                            <TitleContainer>
                                <TitleLine />
                                <Title>
                                    Commentaires
                                </Title>
                                <TitleLine />
                            </TitleContainer>

                            <CommentSection>
                                {comments !== null &&

                                    comments.length > 0 ?

                                    comments.map((comment, index) => {
                                        return (
                                            <Comment key={index}>
                                                {comment}
                                            </Comment>
                                        )
                                    })

                                    :

                                    <NoComment>
                                        Il n'y a pas (et il n'y aura jamais...) de commentaire pour ce post!
                                    </NoComment>
                                }
                            </CommentSection>

                        </BodyContent>
                    </ScrollableContainer>
                </Container>
            </motion.div>
        </Wrapper>
    )
}