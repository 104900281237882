export const comments = [
    {
        "id": 14,
        "comments": [
        ]
    },
    {
        "id": 13,
        "comments": [
        ]
    },
    {
        "id": 12,
        "comments": [
        ]
    },
    {
        "id": 11,
        "comments": [
        ]
    },
    {
        "id": 10,
        "comments": [
        ]
    },
    {
        "id": 9,
        "comments": [
            "Tu as fait des vid\u00e9os incroyables. Super week-end. Bisous marraine \ud83d\udc4d"
        ]
    },
    {
        "id": 8,
        "comments": [
            "Ah, trop contente que ton blog soit de nouveau op\u00e9rationnel !!!! YES!"
        ]
    },
    {
        "id": 7,
        "comments": [
            "Elle est o\u00f9 Cassandra dont tu m\u2019as parl\u00e9 ?",
            "On attend la suite\u2026 tu nous fais croire au gentil Fran\u00e7ais qui fait que de belles photos\u2026 \u00e0 d\u2019autres. ",
            "Faut aller voir des filles la nuit pas des arbres cousin", "plut\u00f4t clair-obscur"
        ]
    },
    {
        "id": 6,
        "comments": [
            "Les photos sous l'eau sont prises \u00e0 la GoPro yes! Et pour le reste c'est au t\u00e9l\u00e9phone",
            "Tu as fait de superbes photos sous marine benjamin \ud83d\udc4d avec une go pro ou ton appareil photo ?",
            "Superbes photos, ton requin l\u00e9opard et un dr\u00f4le de z\u00e8bre (Stegostoma fasciatum), en anglais zebra shark.  a v\u00e9rifier qui a raie-son",
            "Ambiance magique, des acteurs magnifiques de naturel..Profite,profite..",
            "*la raie    ",
            "Tr\u00e8s envie de toucher ta raie "
        ]
    }, 
    { 
        "id": 5, 
        "comments": [
            "Je te conseille le son \"Papa was a Rolling stone\" pour ton prochain post! Bisous mon loulou\ud83e\udd19\ud83c\udffc", 
            "Tu as le nez bien rouge Benjamin, tu as pris froid ou tu as bu? Bisous.", 
            "Magnifique...continue, tu nous fait faire des d\u00e9couvertes. Au fait, y a-t-il des truites dans le coin?...", 
            "Bravo au surfeur pro ! Tu sembles avoir pris un bon coup de soleil ou alors c\u2019\u00e9tait les effets de l\u2019alcool\u2026", 
            "J\u2019attends toujours le vlog\u2026.", 
            "En bas \u00e0 gauche*", 
            "Jolie feuille dans l\u2019angle en bas \u00e0 droite un peu en dessous de la valise sur la photo 8, j\u2019ai rarement vu une telle courbure ", 
            "G\u00e9nial benjamin \ud83c\udfc4\ud83c\udffc\u200d\u2642\ufe0f Tu nous fait voyager en m\u00eame temps C\u2019est magnifique. R\u00e9gale toi bien \u00e0 tr\u00e8s vite pour des News. Le python \u00e0 c\u00f4t\u00e9 des tentes \ud83c\udfd5\ufe0f wahou \ud83d\ude2c\ud83d\ude2cBisous marraine ", 
            "Photos magnifiques qui nous font r\u00eaver !!! Je viendrais bien passer quelques jours avec toi dans la grande et belle Australie. Bisous mon boubou xxx "
        ] 
    }, 
    { 
        "id": 4, 
        "comments": [
            "Merci Ben bisous, Alex.", 
            "Wahou que c\u2019est beau \ud83d\ude09 tu nous fais des belles photos merci \ud83d\udc4d", 
            "Wahou que c\u2019est beau \ud83d\ude09"
        ] 
    }, 
    { 
        "id": 3,
        "comments": [
            "Ceux-l\u00e0 \u00e9taient pas chauds pour la bagarre en tout cas", 
            "C'est un mythe les kangourou qui veulent se fight?", 
            "Je mangerais bien un Kangourou"
        ] 
    }, 
    { 
        "id": 2, 
        "comments": [
            "Apr\u00e8s une seconde observation je dois reconnaitre qu'il y a des choses particuli\u00e8res..",
            "First!!!!!"
        ] 
    }, 
    { 
        "id": 1, 
        "comments": [] 
    }, 
    { 
        "id": 0, 
        "comments": [] 
    }
]