/** @jsxImportSource @emotion/react */
import tw from 'twin.macro'
import {useEffect, useState} from "react";
import { motion } from "framer-motion"
import {Title, TitleContainer, TitleLine} from "../layouts/headings";
import ImageFullScreen from "./popImageFullScreen";
import {useLocation, useNavigate} from "react-router-dom";

const Container = tw.div`flex flex-col justify-center mb-4`;

const ImageGallery = tw.div`
gap-4 outline-0
columns-1 md:columns-2 lg:columns-3
mx-4 md:mx-0
`;
const ImageContainer = tw.div`
relative
flex justify-center items-center sm:cursor-pointer
mb-4
`;

const ImageLegend = tw.div`text-center pt-12 pb-4 md:pb-3 px-2 md:px-3 text-white font-semibold absolute z-10 bottom-0 right-0 left-0 bg-gradient-to-t from-black to-transparent rounded-b-xl`

const hoverVariants = {
    rest:{
        scale:1,
        transition:{
            type:"spring"
        }
    },
    hover:{
        scale:1.025,
        transition:{
            type:"spring"
        }
    }
}

export default function Gallery(props) {

    const [imgID,setImgID] = useState(0);
    const [imagePop,setImagePop] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();


    const handleCloseImg = () => {
        setImagePop(false);
        if (location.pathname.includes("map")){
            navigate('/map/' + props.postId.toString())
        }
        if (location.pathname.includes("blog")){
            navigate('/blog/' + props.postId.toString())
        }
    }
    const rgex = new RegExp('/', 'g')

    useEffect(
        ()=>{
            if (location.pathname.includes("map") && location.pathname.match(rgex).length === 2){
                setImagePop(false)
            }
            if (location.pathname.includes("blog") && location.pathname.match(rgex).length === 2){
                setImagePop(false)
            }
        },
        [location]
    )




    return (
        <Container>
            <TitleContainer>
                <TitleLine/>
                <Title>
                    Galerie de photos
                </Title>
                <TitleLine/>
            </TitleContainer>
            <ImageGallery>
                {props.images.map((image,index)=>{
                    return(
                        <motion.div
                            key={index}
                            variants={hoverVariants}
                            initial="rest"
                            whileHover="hover"
                            animate="rest"
                        >
                            <ImageContainer
                                onClick={
                                ()=>{
                                    setImgID(image.id);
                                    setImagePop(true);
                                    if (location.pathname.includes("map")){
                                        navigate('/map/' + props.postId.toString() + '/' + image.id.toString())
                                    }
                                    if (location.pathname.includes("blog")){
                                        navigate('/blog/' + props.postId.toString() + '/' + image.id.toString())
                                    }
                                }
                            }>
                                <img src={image.src} style={{width:"100%", borderRadius:"0.75rem"}} />
                                <ImageLegend >
                                    {image.comment}
                                </ImageLegend>
                            </ImageContainer>
                        </motion.div>
                    )
                })}
            </ImageGallery>

            {imagePop &&
                <ImageFullScreen handleClose={handleCloseImg}  image={props.images.filter(img => img.id === imgID)[0]}/>
            }

        </Container>

    );
}