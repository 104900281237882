import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import {
    createBrowserRouter,
    createRoutesFromElements,
    RouterProvider,
    Route,
} from "react-router-dom";
import Blog from "./pages/blog-page/main-blog";
import Map from "./pages/map-page/main-map";
import "./index.css"

const router = createBrowserRouter(
    createRoutesFromElements(
            <>
                <Route path={"/"} element={<App/>}/>
                <Route path={"/blog/*"} element={<Blog/>}/>
                <Route path={"/map/*"} element={<Map/>}/>
            </>
    )
)

createRoot(document.getElementById("root")).render(
    <RouterProvider router={router} />
);