/** @jsxImportSource @emotion/react */
import tw from 'twin.macro'
import BlogContainer from "./blog-container";
import {Wrapper} from "../../layouts/sections";
import BlogHeader from "../header";
import {posts} from "../../data/posts"
import {likes} from "../../data/likes"

const Container = tw.div`w-full flex flex-col items-center bg-white`
const TitlesContainer = tw.div`w-full bg-white mb-12 max-w-screen-lg px-3 md:px-6 lg:px-0 `;

const Title = tw.div`font-display text-4xl md:text-5xl text-black text-center font-black px-3 mt-16 mb-10 select-none`
const Description = tw.div`font-display text-xl text-primaryBlue text-center font-bold px-4 select-none`

export default function Blog() {

    return (
        <Wrapper>
                <BlogHeader/>
            <Container>
                <TitlesContainer>
                    <Title css={tw`bg-transparent`}>
                        Retrouvez les récits de mes aventures!
                    </Title>
                    <Description css={tw`bg-transparent`}>
                        Je vais poster ici les étapes de mon voyage à KangourouLand (et même plus)! Survole puis clique sur un post pour l'ouvrir.
                    </Description>
                </TitlesContainer>

                <BlogContainer posts={posts} likes={likes}/>
            </Container>
        </Wrapper>
    );
}