export const likes = [
    {"id": 0, "likes": 68},
    {"id": 1, "likes": 53},
    {"id": 2, "likes": 62},
    {"id": 3, "likes": 90},
    {"id": 4, "likes": 63},
    {"id": 5, "likes": 97},
    {"id": 6, "likes": 57},
    {"id": 7, "likes": 15},
    {"id": 8, "likes": 17},
    {"id": 9, "likes": 21},
    {"id": 10, "likes": 164},
    {"id": 11, "likes": 325},
    {"id": 12, "likes": 751},
    {"id": 13, "likes": 1252},
    {"id": 14, "likes": 537}
]