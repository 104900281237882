/** @jsxImportSource @emotion/react */
import tw from 'twin.macro'
import {} from "@heroicons/react/24/outline";
import {useState} from "react";
import { motion } from "framer-motion"
import CountUp from 'react-countup'
import { useCallback } from "react";
import Particles from "react-particles";
import { loadFull } from "tsparticles";

const Container = tw.div`
relative
select-none
px-4
md:px-8
py-14
rounded-xl
bg-blue-100
my-2
sm:my-4
`

const Title = tw.div`
text-darkViolet
font-bold
text-lg
md:text-2xl
mb-6
`

const SurveyList = tw.div`flex flex-col`

const SurveyItem = tw.div`items-center overflow-hidden sm:cursor-pointer rounded-xl relative flex bg-white px-4 py-4 my-2 first:mt-0 last:mb-0 border-solid border-2 border-primaryBlue`
const ItemText = tw.div`
z-10 font-semibold flex-1
text-base
md:text-lg
`

const particuleOptions = {
    fullScreen: {
        enable: false
    },
    fpsLimit: 60,
    emitters: {
        position: {
            x: 50,
            y: 100
        },
        rate: {
            quantity: 5,
            delay: 0.15
        }
    },
    particles: {
        color: {
            value: [
                "#1E00FF",
                "#FF0061",
                "#E1FF00",
                "#00FF9E"
            ]
        },
        move: {
            decay: 0.03,
            direction: "top",
            enable: true,
            gravity: {
                enable: true
            },
            outModes: {
                top: "none",
                default: "destroy"
            },
            speed: {
                min: 30,
                max: 60
            }
        },
        number: {
            value: 0
        },
        opacity: {
            value: 1
        },
        rotate: {
            value: {
                min: 0,
                max: 360
            },
            direction: "random",
            animation: {
                enable: true,
                speed: 30
            }
        },
        tilt: {
            direction: "random",
            enable: true,
            value: {
                min: 0,
                max: 360
            },
            animation: {
                enable: true,
                speed: 30
            }
        },
        size: {
            value: 5,
            animation: {
                enable: true,
                startValue: "min",
                count: 1,
                speed: 16,
                sync: true
            }
        },
        roll: {
            darken: {
                enable: true,
                value: 25
            },
            enlighten: {
                enable: true,
                value: 25
            },
            "enable": true,
            "speed": {
                "min": 5,
                "max": 15
            }
        },
        wobble: {
            distance: 30,
            enable: true,
            speed: {
                min: -7,
                max: 7
            }
        },
        shape: {
            type: [
                "circle",
                "square",
                "star",
            ],
            options: {}
        }
    },
    detectRetina: true,
}


export default function PopSurvey(props) {

    const [votes,setVotes] = useState(props.votes)
    const [voted,setVoted] = useState(false)

    console.log("votes", votes)


    function handleVote(index){
        if(voted=== false){
            setVoted(true)
            setVotes((prevArray) => {
                const newArray = [...prevArray];
                newArray[index] += 1
                return newArray;
            })

        }
    }

    function bgVariants(index) {
        return(
            {
                rest:{
                    width:0,
                },
                voted:{
                    width: `${ (votes[index] / votes.reduce((partialSum, elem) => partialSum + elem, 0)) * 100 }%`,
                }
            }
        )
    }


    const particlesInit = useCallback(async engine => {
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async (container) => {
        if(voted === false){
            await container.pause()
        }
        else{
            await container.play()
        }
    }, [voted]);



    return (
        <Container>
            <Particles
                css={tw`absolute top-0 left-0 bottom-0 right-0`}
                id="tsparticles"
                init={particlesInit}
                loaded={particlesLoaded}
                options={particuleOptions}
            />
            <Title>
                {props.survey.subject}
            </Title>
            <SurveyList>
                {props.survey.answers.map((label,index)=>{

                    return(
                        <SurveyItem
                            onClick={()=>{handleVote(index)}}
                            key={index}
                            css={voted ? tw`` : tw`transition duration-300 hover:border-primaryOrange`}
                        >
                            <motion.div
                                css={tw`z-1 border-transparent border-2 absolute left-0 bottom-0 top-0 bg-gradient-to-r from-secondaryOrange to-primaryOrange`}
                                variants={bgVariants(index)}
                                initial="rest"
                                animate={!voted ? "rest" : "voted"}
                                transition={{
                                    type:"easeIn",
                                    duration:2
                                }}
                            >
                            </motion.div>
                            <ItemText>
                                {label}
                            </ItemText>
                            <CountUp
                                start={0}
                                end={(votes[index] / votes.reduce((partialSum, elem) => partialSum + elem, 0)) * 100}
                                duration={2}
                            >
                                {({ countUpRef, start }) => (
                                    <div
                                        css={!voted ? tw`hidden font-semibold z-10 ml-4 font-bold text-xl text-primaryOrange` : tw`block font-semibold z-10 ml-4 font-bold text-xl text-primaryOrange`}
                                    >
                                        <span ref={countUpRef} />
                                        %
                                    </div>
                                )}
                            </CountUp>
                        </SurveyItem>
                    )
                })}
            </SurveyList>
        </Container>
    );
}