/** @jsxImportSource @emotion/react */
import tw from 'twin.macro'

import {Wrapper as PageWrapper} from "../../layouts/sections";
import BlogHeader from "../header";
import MapMap from "./map-map";
import { posts } from '../../data/posts';
import { likes } from '../../data/likes';

export default function Map() {

    return (
        <PageWrapper>
            <BlogHeader/>
            {posts !== null &&
                <MapMap posts={posts} likes={likes}/>
            }
        </PageWrapper>
    );
}