/** @jsxImportSource @emotion/react */
import tw from 'twin.macro'
import {XMarkIcon} from "@heroicons/react/24/outline";

const Wrapper = tw.div`fixed inset-0 z-60 outline-none flex flex-col justify-center items-center bg-white`;
const XContainer = tw.div`self-end p-3 text-black cursor-pointer`
const Container = tw.div`
flex-1 flex flex-col items-center justify-center
mx-3 lg:mx-24
`;
const CommentContainer = tw.div`flex justify-center items-center text-sm text-black font-semibold`
const Comment = tw.div`text-center`

export default function ImageFullScreen(props) {

    const handleClickIMG = (e) => {
        e.stopPropagation();
    }

    return (
        <Wrapper onClick={props.handleClose}>
            <XContainer style={{height:"5vh", width:"5vh"}} onClick={props.handleClose}>
                <XMarkIcon/>
            </XContainer>
            <Container onClick={handleClickIMG}>
                <img style={{maxHeight:"80vh", maxWidth:"100%", borderRadius:"0.75rem"}} src={props.image.src}/>
                <CommentContainer style={{height:"10vh"}}>
                    <Comment>
                        {props.image.comment}
                    </Comment>
                </CommentContainer>
            </Container>
        </Wrapper>
    );
}