/** @jsxImportSource @emotion/react */
import tw from 'twin.macro'
import { motion } from "framer-motion"
import {ArrowsPointingOutIcon} from "@heroicons/react/24/outline";
import {useEffect, useState} from "react";
import MessagePopUp from "../postPopUp";
import {useLocation, useNavigate} from "react-router-dom";

const GridWrapper = tw.div``;
const SectionWrapper = tw.div`mx-auto max-w-screen-xl bg-transparent`;
const BlogWrapper = tw.div`
grid 
2xl:mx-0
lg:grid-cols-4
md:grid-cols-3
sm:grid-cols-2 sm:mx-8
grid-cols-1 gap-10 mx-16
bg-transparent
my-6 md:my-10
`;

const BlogPost = tw.div`
text-white text-left sm:cursor-pointer select-none
rounded-3xl flex flex-col justify-end
overflow-hidden
`;

const PostShadowBox = tw.div`
p-4
rounded-b-3xl
bg-gradient-to-t from-black via-black to-transparent
`;

const PostTitle = tw.div`text-xl font-bold`;
const PostDate = tw.div`text-sm font-normal mb-1`;
const PostShortDescription = tw.div`text-sm font-medium mb-1`;

const TwoColumns = tw.div`flex`;
const LeftColumn = tw.div`w-10/12`;
const RigthColumn = tw.div`w-2/12 flex justify-end items-end`;

const PostChipContainer = tw.div`flex flex-wrap text-xs text-white`;
const PostChip = tw.div`rounded-full border-solid border-white border-2 px-2 mr-2 my-1`;


const variantsPost = {
    rest:{
        scale: 1,
        transition:{
            type: "spring",
            stiffness: 400,
            damping: 10
        }
    },
    hover:{
        scale: 1.05,
        transition:{
            type: "spring",
            stiffness: 400,
            damping: 10
        }
    }
}

const variantsPost2 = {
    rest:{
        boxShadow: 'rgba(0, 171, 231, 1) 0px 0px, rgba(0, 171, 231, 0.5) 0px 0px, rgba(0, 171, 231, 0.2) 0px 0px',
        //boxShadow: 'none',
        translateX:"0px",
        translateY:"0px",
        transition:{
            type: "easeOut",
        }
    },
    hover:{
        boxShadow: 'rgba(0, 171, 231, 1) -5px 5px, rgba(0, 171, 231, 0.5) -10px 10px, rgba(0, 171, 231, 0.2) -15px 15px',
        translateX:"15px",
        translateY:"-15px",
        transition:{
            type: "easeIn",
        }
    }
}

const variantsDetail = {
    rest:{
        height: 0,
        opacity: 0,
        transition:{
            type: "easeOut",
            duration: 0.2,
        }
    },
    hover:{
        height: 'auto',
        opacity: 1,
        transition:{
            type: "easeIn",
            duration: 0.2,
        }
    },
}

export default function BlogContainer(props) {


    const navigate = useNavigate();
    const location = useLocation();

    const posts = props.posts;

    const [selectedPost, setSelectedPost] = useState(null);
    const [isOpen, setIsOpen] = useState(false)

    const togglePopUp = () => {
        setIsOpen(prevState => !prevState);
        navigate('/blog');
    }

    useEffect(
        ()=>{
            if (location.pathname === "/blog") {
                setIsOpen(false);
            }
            if (location.pathname !== "/blog" && isOpen === false){
                navigate('/blog')
            }
        },
        [location]
    )

    return (
        <GridWrapper>
            <SectionWrapper>
                {posts !== null &&
                    <BlogWrapper>

                        {posts.map((post, index) => {

                            return (
                                <motion.div
                                    key={index}
                                    initial="rest"
                                    whileHover="hover"
                                    animate="rest"
                                    variants={variantsPost2}
                                    style={{
                                        borderRadius: '1.5rem'
                                    }}
                                >
                                    <BlogPost
                                        onClick={
                                            () => {
                                                setSelectedPost(post.id);
                                                navigate('/blog/' + post.id.toString());
                                                setIsOpen(true);
                                            }
                                        }
                                        style={{
                                            backgroundImage: `url(${post.cover})`,
                                            backgroundSize: "cover",
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat',
                                            height: "400px",
                                        }}
                                    >
                                        <PostShadowBox>
                                            <PostTitle>{post.title}</PostTitle>
                                            <PostDate>{post.date}</PostDate>

                                            <motion.div
                                                variants={variantsDetail}
                                            >
                                                <PostShortDescription>
                                                    {post.shortDescription}
                                                </PostShortDescription>
                                                <TwoColumns>
                                                    <LeftColumn>
                                                        <PostChipContainer>
                                                            {post.hTags.map((htag,index) => {
                                                                return (
                                                                    <PostChip key={index}>{htag}</PostChip>
                                                                )
                                                            })}
                                                        </PostChipContainer>
                                                    </LeftColumn>
                                                    <RigthColumn>
                                                        <motion.div
                                                            style={{
                                                                height: "24px",
                                                                width: "24px",
                                                                display: "flex",
                                                                textAlign: "center",
                                                                justifyContent: "center",
                                                            }}
                                                            animate={{scale: 1.25}}
                                                            transition={{
                                                                repeat: Infinity,
                                                                repeatType: "reverse",
                                                                repeatDelay: 2,
                                                                duration: 1,
                                                                type: "spring",
                                                                stiffness: 400,
                                                                damping: 10
                                                            }}
                                                        >
                                                            <ArrowsPointingOutIcon/>
                                                        </motion.div>
                                                    </RigthColumn>
                                                </TwoColumns>

                                            </motion.div>

                                        </PostShadowBox>
                                    </BlogPost>
                                </motion.div>
                            )
                        })}

                    </BlogWrapper>
                }
            </SectionWrapper>

            {isOpen &&
                <MessagePopUp isOpened={isOpen} post={posts.filter((e)=> e.id === selectedPost)[0]} likeObj={props.likes.filter((e)=> e.id === selectedPost)[0]} handleClose={togglePopUp}/>
            }

        </GridWrapper>
    );
}