/** @jsxImportSource @emotion/react */
import tw from 'twin.macro'
import {PauseCircleIcon, PlayCircleIcon} from "@heroicons/react/24/solid";
import WaveSurfer from "wavesurfer.js";
import {useEffect, useRef, useState} from "react";


const Container = tw.div`flex flex-row`
const PlayPauseIconContainer = tw.div`
flex items-center justify-center
md:pr-4
`

const Wave = tw.div`w-full mx-1`

export default function PopAnecdote(props) {

    const [audioPlaying,setAudioPlaying] = useState(false)

    const containerRef = useRef()
    const waveSurferRef = useRef({
        audioPlaying: () => false,
    })

    useEffect(()=>{
        if(audioPlaying === true && props.musicPlaying === true){
            props.toggleMusic()
        }
    },[audioPlaying])

    useEffect(() => {
        const waveSurfer = WaveSurfer.create({
            container: containerRef.current,
            waveColor: '#2DC7FF',
            progressColor:'#0081AF',
            responsive: true,
            cursorWidth: 0,
            barWidth: 2,
            barHeight: window.innerWidth < 600 ? 3 : 5
        })
        waveSurfer.load(props.audioSrc)
        waveSurfer.on('ready', () => {
            waveSurferRef.current = waveSurfer
        })

        return () => {
            waveSurfer.destroy()
        }

    }, [props.audioSrc])


    return (
        <Container>
            <PlayPauseIconContainer
                onClick={() => {
                    waveSurferRef.current.playPause()
                    setAudioPlaying(waveSurferRef.current.isPlaying())
                }}
            >
                {audioPlaying === false ?
                    <PlayCircleIcon
                        css={tw`w-14 h-14 sm:w-24 sm:h-24 sm:cursor-pointer fill-thirdBlue hover:fill-primaryBlue transition duration-300`}
                    />
                    :
                    <PauseCircleIcon
                        css={tw`w-14 h-14 sm:w-24 sm:h-24 sm:cursor-pointer fill-thirdBlue hover:fill-primaryBlue transition duration-300`}
                    />
                }
            </PlayPauseIconContainer>
            <Wave
                ref={containerRef}
            >
            </Wave>
        </Container>
    );
}